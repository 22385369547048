import React, { useState } from "react";
import Dropdown from "./Dropdown";
import { images } from "../assets/styles/Images";
import { dashboard } from "../routes/routesDefined";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { useLoggedContext } from "../context/provider";
import { useNavigate, useLocation } from "react-router-dom";
import { employeeMediaUrl, mediaUrl } from "../utils/config";
import VideoPopup from "./VideoPopup"; // Import the new component

export default function AdminNavbar({ sidebarWidth, setShowBackDrop }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedin, setShowSidebar, user } = useLoggedContext();

  // Add state for video popup
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);

  // Path to your tutorial video
  const tutorialVideoSrc =
    location.pathname === "/dashboard"
      ? "videos/analytical-board.mp4"
      : location.pathname === "/profile"
        ? "videos/profile.mp4"
        : location.pathname === "/campuses"
          ? "videos/site.mp4"
          : location.pathname === "/deparment"
            ? "videos/department.mp4"
            : location.pathname === "/admins"
              ? "videos/admin.mp4"
              : location.pathname === "/employees"
                ? "videos/employee.mp4"
                : location.pathname === "/cause-for-concern"
                  ? "videos/cause-for-concern.mp4"
                  : location.pathname === "/reports"
                    ? "videos/report.mp4"
                    : location.pathname === "/recent-evaluations"
                      ? "videos/recent-evaluation.mp4"
                      : location.pathname === "/contact-book"
                        ? "videos/referral-list.mp4"
                        : location.pathname === "/notifications"
                          ? "videos/broadcast.mp4"
                          : location.pathname === "/support-tickets"
                            ? "videos/support-ticket.mp4"
                            : "videos/analytical-board.mp4"; // Update this path to match your actual video path

  return (
    <>
      <nav
        className={
          " z-10 flex h-[10vh] bg-white py-4 md:shadow-card lg:pr-16 2xl:py-6 3xl:h-[8vh] " +
          (" md:ml-[" + sidebarWidth + "px] ") +
          (isLoggedin ? "fixed top-0 w-full" : "")
        }
      >
        <div className="container relative mx-auto flex max-w-full items-center justify-between">
          <div className=" top-[-5px] md:hidden"></div>

          <div className="ml-5 flex items-center justify-center md:justify-start">
            <div className="lg:hidden">
              <button
                onClick={() => {
                  setShowSidebar((prev) => (prev === "left-0" ? "-left-64" : "left-0"));
                  setShowBackDrop((prev) => !prev);
                }}
              >
                <Bars3Icon className="h-7 w-7 cursor-pointer" />
              </button>
            </div>
            <div className="navbar-brand cursor-pointer pr-4" style={{ width: sidebarWidth + "px" }} onClick={() => navigate(dashboard)}>
              <img src={images.NewLogo} alt="Work well logo" className=" mx-auto w-[10rem] md:mx-0  md:w-[12rem] md:pl-[3rem] 2xl:w-[14rem]" />
            </div>
            <div className="h-[8vh] border-l border-secondary-color/25 pl-4 3xl:h-[6vh]">
              {/* <img src={images.National_Icon} alt="Work well logo" className="w-10 mr-5" /> */}
            </div>
            {/* <div>
              <img src={images.Award_Icon} alt="Work well logo " className="w-12" />
            </div> */}

            <div className={`text-grayColor500  px-5 text-sm font-medium leading-normal md:block  lg:text-lg `}>
              Empowering Employees: Cultivate wellness, inspire excellence
            </div>
          </div>

          <div className="flex items-center justify-end pr-4 lg:pr-0">
            <button
              type="button"
              className="font-poppins mr-5 h-9 w-full gap-x-3 rounded-full border border-lightGreenColor px-8 text-center text-base font-medium text-lightGreenColor transition-colors hover:bg-lightGreenColor hover:bg-opacity-10 focus:outline-none"
              onClick={() => setIsVideoPopupOpen(true)}
            >
              Tutorial
            </button>
            <h2 className="text-grayColor500 mr-4 hidden shrink-0 text-sm font-medium leading-normal md:block lg:text-lg">
              Hi, {user?.user?.employee?.length > 0 ? user?.user?.employee[0]?.name : "N/A"}
            </h2>

            <Dropdown />

            <div className="flex shrink-0 flex-col items-center justify-center border-l border-secondary-color/25 pl-3">
              <img
                src={
                  user?.user?.nursery?.length > 0 &&
                  user?.user?.nursery[0]?.profile_image_url !== null &&
                  user?.user?.nursery[0]?.profile_image_url !== ""
                    ? `${mediaUrl}${user?.user?.nursery[0]?.logo}`
                    : images.placeholder
                }
                alt="studio"
                className="mb-2 h-10 w-10 rounded-full border border-secondary object-contain"
              />
              <p className="text-xs font-bold text-secondary-color">{user?.user?.nursery?.length > 0 ? user?.user?.nursery[0]?.name : "N/A"}</p>
            </div>
          </div>
        </div>
      </nav>

      {/* Video Popup */}
      <VideoPopup isOpen={isVideoPopupOpen} onClose={() => setIsVideoPopupOpen(false)} videoSrc={tutorialVideoSrc} />
    </>
  );
}
