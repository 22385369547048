import React, { useEffect, useRef } from "react";

const VideoPopup = ({ isOpen, onClose, videoSrc }) => {
  const videoRef = useRef(null);

  // Handle escape key to close the popup
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscKey);
    }

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isOpen, onClose]);

  // Stop video when popup is closed
  useEffect(() => {
    if (!isOpen && videoRef.current) {
      videoRef.current.pause();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/75" onClick={onClose}>
      <div className="relative mx-4 w-full max-w-6xl">
        <div className="overflow-hidden rounded-lg shadow-xl">
          <video 
            ref={videoRef}
            controls 
            autoPlay
            className="h-full w-full object-contain" 
            width="100%"
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;